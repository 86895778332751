import * as React from 'react';
import { Tab, Tabs } from "@mui/material";
import { Navigate, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { appTabTheme, useAppStyles } from "../../../styles";
import { pushUrl } from 'src/redux/ReduxConfig';

export interface LinkTab<T extends string> {
    id: T;
    label: React.ReactNode;
    subLabel?: React.ReactNode;
    node?: React.ReactNode;
    getIcon?: (active: boolean) => React.ReactNode;
}

interface SettingsProperties<T extends string> {
    tabs: Array<LinkTab<T>>;
    initial: T;
    pub?: boolean;
    sticky?: boolean;
    maxWidth?: number;
    onSelectedTab: (tab: T) => void;
}

type Props<T extends string> = SettingsProperties<T> & { parentUrl: string };

export function tabName(parentUrl: string, defaultTab: string) {
    // const pathName = location.pathname.replace(parentUrl + '/', '');
    // const tab = pathName.indexOf('/') > 0 ? pathName.substring(0, pathName.indexOf('/')) : pathName;
    // return tab;
    const pathName = location.pathname.replace(parentUrl, '').replace('/', '');
    return pathName || defaultTab;
}

function LinkTabs<T extends string>(props: Props<T>) {
    const { tabs, parentUrl, onSelectedTab, maxWidth, pub, sticky, initial } = props;
    function changeTab(_: React.ChangeEvent<{}>, value: T) {
        onSelectedTab(value);
        pushUrl(`${parentUrl}/${value}`)
    }
    const classes = useAppStyles();
    const className = (pub ? classes.tabRootPub : '') + ' ' + (sticky ? classes.stickyTop : '');
    const tab = tabName(parentUrl, initial) as T;
    const initialTab = tabs.find(t => t.id === initial);
    return (
        <div className={className} style={{ maxWidth }}>
            <ThemeProvider theme={appTabTheme}>
                <Tabs value={tab} onChange={changeTab}>
                    {tabs.map((t, i) =>
                        <Tab key={t.id} label={t.label} value={t.id} />)}
                </Tabs>
            </ThemeProvider>
            <Routes>
                {tabs.map(t => <Route key={t.id} path={t.id} element={t.node} />)}
                {initialTab &&
                    <Route path="/*" element={<Navigate to={initialTab.id} replace />} />}
            </Routes>
        </div>
    );
}

export default LinkTabs;
