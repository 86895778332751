export const VERSION = '1.0.250123';

export const TEST_SERVER = true; // test or product services

export const LOCAL_FUNC_EMULATOR = false;

export const DEBUG = TEST_SERVER; // UI debug mode

export const SRV_TRCK = true;

export const projectId = TEST_SERVER ? 'golfpadevents-test' : 'project-8831674143385441783';

export const TIME_FORMAT = 'h:mm A';
export const MON_DATE_FORMAT = 'MMM D, YYYY';
export const MONTH_DATE_FORMAT = 'MMMM D, YYYY';
export const MONTH_WEEK_DATE_FORMAT = 'MMM, D, YYYY - dddd';
export const MONTH_DATETIME_FORMAT = MONTH_DATE_FORMAT + ' — h:mm A';
export const EVENT_DATETIME_FORMAT_DLG = MONTH_DATE_FORMAT + ' - dddd';
export const EVENT_DATETIME_FORMAT_LOG = 'D-MMM-YYYY h:mm';

export const ITEM_ABOUT_ID = 'aboutID';
export const ITEM_NEW_EVENT_ID = 'newEventID';

export const FbConfig: ConfigType = {
    projectId,
    apiKey: TEST_SERVER ? 'AIzaSyDQJ1dRb-8Gq4cFQ8f_CH8UutUyfhIJS2o' : 'AIzaSyA8VdZgtkAq5Sk3y1mAh0LAf5aYqv5eSGc',
    appId: TEST_SERVER ? '1:956711991742:web:c69befa6fdffc1e14121ef' : '1:919314935048:web:b59b20a1b1002df545d0bd',
    authDomain: projectId + '.firebaseapp.com',
    databaseURL: 'https://' + projectId + '.firebaseio.com',
    storageBucket: projectId + '.appspot.com',
    messagingSenderId: '919314935048',
    measurementId: TEST_SERVER ? 'G-CDXHN3F6X7' : 'G-0Y6S65BL83'
};

export const MxpConfig = {
    token: TEST_SERVER ?
        '2c671862faeaecd3bb678c321d2eb62e' : // Events Test
        '5db8b1ea2fc8ab4ccb5d707887787769', // Events
    trackAutomaticEvents: true,
    config: {
        debug: false, /* DEBUG, */
        ignore_dnt: true /* DEBUG */
    }
};

export class PayPalConfig {
    static readonly payPalClientId = 'AecP-qUN0BrCXuSz12CX_VtvqVcwjo5wYOosMHJ3Xwa9M1Y21I2phFl04l7XhIWUItpe_M0wPHLjXyrF';
    static readonly payPalClientSecret = 'EN7fu2JuadJvpnHGrEUyVuS_fIQkrRZ9vDcQSFRch7vKDAep5OMTfxeKDFB14Oh4FGqhWHRbIszgLrKu';
    static readonly getAuthHeaderValue = () => `${this.payPalClientId}:${this.payPalClientSecret}`;
}

export class Urls {
    static readonly baseUrl = TEST_SERVER ? 'https://t2.golfpad.events' : 'https://golfpad.events';
    static readonly baseFbFunctionsUrl = TEST_SERVER && LOCAL_FUNC_EMULATOR ?
        `http://127.0.0.1:5001/${projectId}/us-central1` :
        `https://us-central1-${projectId}.cloudfunctions.net`;
    static readonly baseServerUrl = TEST_SERVER ? 'https://contorra-test.appspot.com' : 'https://dashboard.golfpadgps.com';
    static readonly baseSuppUrl = 'https://support.golfpadgps.com';
    static readonly baseGHINUrl = 'https://api-uat.ghin.com/api/v1';
    static readonly supportRelURL = `/support/solutions/folders/6000235123`;
    static readonly helpURL = `${Urls.baseSuppUrl}${Urls.supportRelURL}`;
    static readonly helpImportURL = `${Urls.baseSuppUrl}/a/solutions/articles/6000233657`;
    static readonly helpFlightsURL = `${Urls.baseSuppUrl}/a/solutions/articles/6000235727`;
    static readonly checkAccessCode = `${Urls.baseFbFunctionsUrl}/checkAccessCode`;
    static readonly sendMail = `${Urls.baseFbFunctionsUrl}/sendMail`;
    static readonly sendInviteMail = `${Urls.baseFbFunctionsUrl}/sendInviteMail`;
    static readonly listUsers = `${Urls.baseFbFunctionsUrl}/listUsers`;
    static readonly listUsersEmails = `${Urls.baseFbFunctionsUrl}/listUsersEmails`;
    static readonly findUser = `${Urls.baseFbFunctionsUrl}/findUser`;
    static readonly setUserEmail = `${Urls.baseFbFunctionsUrl}/setUserEmail`;
    static readonly regGolfer = `${Urls.baseFbFunctionsUrl}/regUser`;
    static readonly getEventContact = `${Urls.baseFbFunctionsUrl}/eventContact`;
    static readonly createLeaderboard = `${Urls.baseFbFunctionsUrl}/createLiveLeaderboard`;
    static readonly joinLeaderboard = `${Urls.baseFbFunctionsUrl}/joinLiveLeaderboard`;
    static readonly createPaypalOrder = `${Urls.baseFbFunctionsUrl}/createPaypalOrder`;
    static readonly capturePaypalOrder = `${Urls.baseFbFunctionsUrl}/capturePaypalOrder`;
    static readonly findUserById = `${Urls.baseFbFunctionsUrl}/findUserById`;
    static readonly checkAction = `${Urls.baseFbFunctionsUrl}/checkAction`;
    static readonly bgQuery = `${Urls.baseFbFunctionsUrl}/bgQuery`;
    static readonly duplicateEvent = `${Urls.baseFbFunctionsUrl}/dupEvent`;
    static readonly checkout = `${Urls.baseFbFunctionsUrl}/checkout`;
    static readonly cancelSubscription = `${Urls.baseFbFunctionsUrl}/cancelSubscription`;
    static readonly listProducts = `${Urls.baseFbFunctionsUrl}/listProducts`;
    static readonly listSubscriptions = `${Urls.baseFbFunctionsUrl}/listSubscriptions`;
    static readonly handleEvent = `${Urls.baseFbFunctionsUrl}/handleEvent`;

    static readonly listCourses = `${Urls.baseServerUrl}/rest/course/list`;
    static readonly listTees = `${Urls.baseServerUrl}/rest/course/tees`;
    static readonly invitedGolfersInfo = `${Urls.baseServerUrl}/rest/events/invited_golfers_info`;
    static readonly logOut = `${Urls.baseServerUrl}/logout`;
    static readonly logIn = `${Urls.baseServerUrl}/login`;
    static readonly signUp = `${Urls.baseServerUrl}/signup`;
    static readonly requestPwdReset = `${Urls.baseServerUrl}/rest/auth/request_reset`;
    static readonly handicapGHINRequestUrl = `${Urls.baseServerUrl}/rest/ghin/hi`;

    static readonly genderizeAPI = `https://api.genderize.io`;
    static readonly groupRoundsHelpArticle = `${Urls.baseSuppUrl}/support/solutions/articles/6000159107-what-are-group-rounds-in-golf-pad-`;
    static readonly lllinkHelpArticle = `${Urls.baseSuppUrl}/a/solutions/articles/6000255336`;
    static readonly landingPageLink = 'https://golfpadgps.com/events';
    static readonly whatIsLLLink = 'https://support.golfpadgps.com/support/solutions/articles/6000253103';
    static readonly eventInvitationInfoLink = 'https://support.golfpadgps.com/support/solutions/articles/6000245816-how-to-accept-an-event-invitation-on-the-golf-pad-app';
    static readonly emailInvitationsSupportLink = 'https://support.golfpadgps.com/support/solutions/articles/6000245809-email-invitations-to-golfers-in-your-event';
    static readonly revokeConnectionSupportLink = 'https://support.golfpadgps.com/support/solutions/articles/6000269165';
    static readonly eventsUserSession = 'https://calendly.com/jon-golfpad/events-user-session';
    static readonly payPalVerifiedAccountArticleLink = 'https://www.paypal.com/us/cshelp/article/how-do-i-verify-my-paypal-account-help434';
    static readonly yourPayPalAccountLink = 'https://www.paypal.com/myaccount/profile';
    static readonly payPalLimitsAndHoldsArticleLink = 'https://www.paypal.com/kz/cshelp/article/why-is-my-payment-on-hold-or-unavailable-help126';
    static readonly payPalCardPaymentsArticleLink = 'https://www.paypal.com/businessmanage/preferences/website';
    static readonly whatIsEventsArticle = 'https://support.golfpadgps.com/support/solutions/articles/6000218244-what-is-golf-pad-events-';
    static readonly enablePayPalFeesArticle = 'https://support.golfpadgps.com/a/solutions/articles/6000271486';
    static readonly payPalBusinessAccountSignUpArticle = 'https://www.paypal.com/c2/webapps/mpp/how-to-guides/sign-up-business-account?locale.x=en_C2';
    static readonly payPalFeesArticle = 'https://www.paypal.com/us/webapps/mpp/merchant-fees';

    static readonly deepLinkDomain = 'golfpad.events/applinks';
    static readonly appIBI = TEST_SERVER ? 'com.contorra.golfpad' : 'com.contorra.Golf-Pad';
    static readonly makeDeepLink = (eid: string) => `https://${Urls.deepLinkDomain}/?link=${Urls.baseUrl}/eventInvites/${eid}/{cid}&apn=com.contorra.golfpad&isi=446320556&ibi=${Urls.appIBI}`;
    static readonly makeUniqueDeepLink = (eid: string, cid: string) => `https://${Urls.deepLinkDomain}/?link=${Urls.baseUrl}/eventInvites/${eid}/${cid}&apn=com.contorra.golfpad&isi=446320556&ibi=${Urls.appIBI}`;
    static readonly makeScorecardInviteDeepLink = (eventId: string, groupId: string) => `https://${Urls.deepLinkDomain}/?link=${Urls.baseUrl}/scorecardInvites/${eventId}/${groupId}&apn=com.contorra.golfpad&isi=446320556&ibi=${Urls.appIBI}`;
    static readonly makeAppLink = (eid: string) => `https://${Urls.deepLinkDomain}/?link=${Urls.baseUrl}/eventInfo/${eid}&apn=com.contorra.golfpad&isi=446320556&ibi=${Urls.appIBI}`;
    static readonly makeAboutLink = (publicId: string) => `${Urls.baseUrl}/event/${publicId}/about`;
    static readonly makePaymentLink = (publicEventId: string, contactId: string) => `${Urls.baseUrl}/event/${publicEventId}/about?payingUserId=${contactId}`;

    static readonly golfImage = '/img/golf-award-secondary.svg';
    static readonly defaultBanner = '/img/banner.jpg';
    static readonly defaultBadge = '/img/badge.jpg';
    static readonly defaultLLBanner = '/img/LLBanner.jpg';
    static readonly defaultLLBadge = '/img/LLBadge.svg';
    static readonly connectedPhoneIcon = '/img/connected_icon_transparent24x24.png';
    static readonly checkMarkBlueIcon = '/img/checkMarkBlue.svg';
    static readonly welcomeCreateEventImg = '/img/welcomeCreateEvent.svg';
    static readonly welcomeAddGolfersImg = '/img/welcomeAddGolfers.svg';
    static readonly welcomeViewScoringImg = '/img/welcomeViewScoring.svg';
    static readonly scheduleOnlineSessionImg = '/img/scheduleOnlineSession.svg';
    static readonly welcomeCreateEvent = '/img/welcomeCreateEvent.svg';
    static readonly welcomeAddGolfers = '/img/welcomeAddGolfers.svg';
    static readonly welcomeViewScoring = '/img/welcomeViewScoring.svg';
    static readonly iconPayPalLogo = '/img/PayPalLogo20x24.svg';
    static readonly payPalLogoFull = '/img/PayPalLogo.svg';
    static readonly iconSwitch = '/img/iconSwitch.svg';
    static readonly uploadIcon = '/img/uploadIcon24.svg';
    static readonly uploadPrimaryIcon24 = '/img/uploadPrimaryIcon24.svg';
}

export class Emails {
    static readonly supportEmail = 'support@golfpadgps.com';
    static readonly updatesEmail = 'updates@golfpadgps.com';
    static readonly infoEmail = 'info@golfpad.com';
}

type ConfigType = {
    projectId: string;
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
};

export class Opts {
    static readonly showAllGolferTees = false;
}

export const openSessionSchedule = () => window.open(Urls.eventsUserSession, '_blank');
