//
// Shared utility functions
//

import { DEBUG } from './config';

export const charA = 65;
export const MAX_STR_LEN_DEFAULT = 30;
export const TIMEZONE_OFFSET = -new Date().getTimezoneOffset();
export const TIMEZONE_OFFSET_MILLIS = TIMEZONE_OFFSET * 1000 * 60;
export const HOUR_MILLIS = 3_600_000;
export const DAY_MILLIS = 24 * HOUR_MILLIS;

export interface Pair<K, V> {
    key: K;
    value: V;
}

export type Func<T = void> = () => T;
export type Func1<A1, T = void> = (a1: A1) => T;

export function getUTCMidnight(date: number) {
    const result = new Date(date);
    result.setUTCFullYear(result.getFullYear(), result.getMonth(), result.getDate());
    result.setUTCHours(0, 0, 0, 0);
    return result.getTime();
}

export function getTimeZoneOffset(atMoment: number) {
    return -new Date(atMoment).getTimezoneOffset();
}

export function getTimeZoneOffsetMs(atMoment: number) {
    return getTimeZoneOffset(atMoment) * 1000 * 60;
}

export function evalAvg(data: Array<number>): number {
    if (data.length === 0) {
        return 0;
    }
    return data.reduce((l, d) => l + d, 0) / data.length;
}

export function sumArray(data: Array<number>): number {
    if (data.length === 0) {
        return 0;
    }
    return data.reduce((l, d) => l + d, 0);
}

export function msToMin(ms: number): number {
    return ms / 60000;
}

export function round(value: number, decimals: number): number {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
}

export function range(start: number, end: number): Array<number> {
    const count = Math.abs(end - start);
    const sign = Math.sign(end - start);
    return Array.from(Array(count).keys()).map(v => start + sign * v);
}

export function array<T = number>(size: number, def: T): Array<T> {
    return Array.from(Array(size).keys()).map(_v => def);
}

export function arrayUndef(size: number): Array<number | undefined> {
    return Array.from(Array(size).keys()).map(_v => undefined);
}

export const TIME_FORMATTER = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

export function formatTime(dateTime: Date | number, toLower?: boolean): string {
    if (!dateTime) {
        return '';
    }
    const str = TIME_FORMATTER.format(dateTime);
    return toLower ? str.replace(' AM', 'am').replace(' PM', 'pm') : str;
}

const MONTH_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const MONTH_LONG = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const MONTH_NUM = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export function toDate(dateTime: Date | number): Date {
    const date = (dateTime instanceof Date) ? dateTime as Date : new Date(dateTime);
    return date;
}

// Format: 2021-07-13
export function formatDateSlashed(dateTime: Date | number): string {
    if (!dateTime) {
        return '';
    }
    const date = toDate(dateTime);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return MONTH_NUM[monthIndex] + '/' + day + '/' + year;
}

// Format: 13-Jun-2021
export function formatDateDashed1(dateTime: Date | number): string {
    if (!dateTime) {
        return '';
    }
    const date = toDate(dateTime);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return day + '-' + MONTH_SHORT[monthIndex] + '-' + year;
}

// Format: 2021-07-13
export function formatDateDashed2(dateTime: Date | number): string {
    if (!dateTime) {
        return '';
    }
    const date = toDate(dateTime);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return year + '-' + MONTH_NUM[monthIndex] + '-' + day;
}

// Format: Jun 13, 2021
export function formatDateUS(dateTime: Date | number): string {
    if (!dateTime) {
        return '';
    }
    const date = toDate(dateTime);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return MONTH_SHORT[monthIndex] + ' ' + day + ', ' + year;
}

// Format: June 13, 2021
export function formatDateUS2Long(dateTime: Date | number): string {
    if (!dateTime) {
        return '';
    }
    const date = toDate(dateTime);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return MONTH_LONG[monthIndex] + ' ' + day + ', ' + year;
}

export function formatDayOfWeek(dateTime: Date | number): string {
    if (!dateTime) {
        return '';
    }
    const date = toDate(dateTime);
    return date.toLocaleString('en-us', { weekday: 'long' });
}

export function dd(n: number): string {
    return n < 10 ? '0' + n : '' + n;
}

export function ddd(n: number): string {
    return n < 10 ? '00' + n : n < 100 ? '0' + n : '' + n;
}

export function formatDateTimeMs(dateTime: Date | number): string {
    if (!dateTime) {
        return '';
    }
    const date = toDate(dateTime);
    const day = date.getDate();
    const monthIndex = date.getUTCMonth();
    const year = date.getUTCFullYear();
    const hrs = date.getUTCHours();
    const min = date.getUTCMinutes();
    const sec = date.getUTCSeconds();
    const ms = date.getUTCMilliseconds();
    return year + '_' + dd(monthIndex) + '_' + dd(day) + '_' + dd(hrs) + dd(min) + dd(sec) + '.' + ddd(ms);
}

const regex = /^\d{4,}_\d{2}_\d{2}_\d{6}\.\d{3}$/; // 2023_03_12_033319.541
const defaultValue = -100;

export function decodeDateTimeMsString(dateTimeMs: string) {
    if (!dateTimeMs || !regex.test(dateTimeMs)) {
        return;
    }
    let year = defaultValue;
    let monthIndex = defaultValue;
    let day = defaultValue;
    let hrs = defaultValue;
    let minutes = defaultValue;
    let sec = defaultValue;
    let ms = defaultValue;
    dateTimeMs.split('_').forEach((value, index) => {
        const numValue = Number.parseInt(value, 10);
        if (index === 0) {
            year = numValue;
        } else if (index === 1) {
            monthIndex = numValue;
        } else if (index === 2) {
            day = numValue;
        } else if (index === 3) {
            value.split('.').forEach((val, ind) => {
                if (ind === 0) {
                    hrs = Number.parseInt(val.substring(0, 2), 10);
                    minutes = Number.parseInt(val.substring(2, 4), 10);
                    sec = Number.parseInt(val.substring(4, 6), 10);
                } else if (ind === 1) {
                    ms = Number.parseInt(val, 10);
                }
            });
        }
    });
    const utcDaysOffset = - new Date().getTimezoneOffset() / (60 * 24);
    return (year === defaultValue || monthIndex === defaultValue || day === defaultValue || hrs === defaultValue ||
        minutes === defaultValue || sec === defaultValue || ms === defaultValue) ? undefined :
        Date.UTC(year, monthIndex, day, hrs + utcDaysOffset, minutes, sec, ms);
}

export function formatDateTime(dateTime: Date | number): string {
    if (!dateTime) {
        return '';
    }
    const date = toDate(dateTime);
    return formatDateUS(date) + ' ' + formatTime(date);
}

export function getDayTime(dateTime: Date | number): number {
    if (!dateTime) {
        return 0;
    }
    const date = toDate(dateTime);
    return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
}

/*
 * 1 Jan 2000
 * 1-3 Jan 2000
 * 30 Jan - 1 Feb 2000
 * 30 Dec 2000 - 1 Jan 2001
 */
export function formatDateRange1(startTime: Date | number, finishTime: Date | number): string {
    if (!startTime || !finishTime) {
        return '';
    }
    const date1 = toDate(startTime);
    const date2 = toDate(finishTime);
    if (date1.getFullYear() !== date2.getFullYear()) {
        return `${date1.getDate()} ${MONTH_SHORT[date1.getMonth()]} ${date1.getFullYear()} - ${date2.getDate()} ${MONTH_SHORT[date2.getMonth()]} ${date2.getFullYear()}`;
    } else if (date1.getMonth() !== date2.getMonth()) {
        return `${date1.getDate()} ${MONTH_SHORT[date1.getMonth()]} - ${date2.getDate()} ${MONTH_SHORT[date2.getMonth()]} ${date1.getFullYear()}`;
    } else if (date1.getDate() !== date2.getDate()) {
        return `${date1.getDate()}-${date2.getDate()} ${MONTH_SHORT[date1.getMonth()]} ${date1.getFullYear()}`;
    } else {
        return `${date1.getDate()} ${MONTH_SHORT[date1.getMonth()]} ${date1.getFullYear()}`;
    }
}

/*
 * January 1, 2000
 * January 1-3, 2000
 * January 30  - February 1,2000
 * December 30, 2000 - January, 1 2001
 */
export function formatDateRange2(startTime: Date | number, finishTime: Date | number): string {
    if (!startTime || !finishTime) {
        return '';
    }
    const date1 = toDate(startTime);
    const date2 = toDate(finishTime);
    if (date1.getFullYear() !== date2.getFullYear()) {
        return `${MONTH_LONG[date1.getMonth()]} ${date1.getDate()}, ${date1.getFullYear()} - ${MONTH_LONG[date2.getMonth()]} ${date2.getDate()}, ${date2.getFullYear()}`;
    } else if (date1.getMonth() !== date2.getMonth()) {
        return `${MONTH_LONG[date1.getMonth()]} ${date1.getDate()} - ${MONTH_LONG[date2.getMonth()]} ${date2.getDate()}, ${date1.getFullYear()}`;
    } else if (date1.getDate() !== date2.getDate()) {
        return `${MONTH_LONG[date1.getMonth()]} ${date1.getDate()}-${date2.getDate()}, ${date1.getFullYear()}`;
    } else {
        return `${MONTH_LONG[date1.getMonth()]} ${date1.getDate()}, ${date1.getFullYear()}`;
    }
}

export function formatCurrency(currency: string, value: number) {
    return Intl.NumberFormat('en', { currency, style: 'currency', minimumFractionDigits: 0 }).format(value);
}

export function roundCurrency(value: number): string {
    if (!value) {
        return '0';
    }
    const r = round(value, 2);
    if (r % 1 > 0 && (r * 10 % 1) === 0) {
        return r.toString() + '0';
    }
    return r.toString();
}

const SCHARS = 'ABCDEFGHJKMNPQRSTUVWXY3456789';

export function randomString(len: number) {
    // OLD: const publicId = Math.random().toString(36).substr(2, 5).toUpperCase();
    let s = '';
    for (let i = 0; i < len; i++) {
        let charIdx = Math.floor(Math.random() * SCHARS.length);
        // make sure
        if (charIdx >= SCHARS.length) {
            charIdx = SCHARS.length - 1;
        }
        s += SCHARS[charIdx];
    }
    return s;
}

export function upperFirstLetter(text: string): string {
    if (text && text.length > 0) {
        text = text.substring(0, 1).toUpperCase() + text.substring(1);
    }
    return text;
}

export function makeFriendlyString(text: string, upperAllWords: boolean): string {
    if (text === 'skins_individual') {
        return 'Skins';
    }
    if (text === 'gross+net') {
        return 'Gross+Net';
    }
    if (text && text.length > 0) {
        if (upperAllWords) {
            text = text.replace(/_/g, ' ').split(' ').map(s => upperFirstLetter(s)).join(' ');
        } else {
            text = upperFirstLetter(text.replace(/_/g, ' '));
        }
    }
    return text;
}

export function stringifyMapFriendly(map: Map<string, string>): string {
    let text = JSON.stringify(Array.from(map.entries()));
    while (text.indexOf('","') > -1) {
        text = text.replace('","', ': ');
    }
    text = text.replace('[["', '').replace('"]]', '');
    while (text.indexOf('"],["') > -1) {
        text = text.replace('"],["', ' ');
    }
    text = makeFriendlyString(text, true);
    return text;
}

export function objProps(obj: any, rec?: number, maxStrLen?: number, noQuotes?: boolean) {
    if (obj === null) {
        return 'null';
    }
    if (obj === undefined) {
        return 'undefined';
    }
    if (typeof obj === 'string') {
        const maxLen = maxStrLen || MAX_STR_LEN_DEFAULT;
        if (obj.length <= maxLen) {
            return noQuotes ? obj : `"${obj}"`;
        } else {
            const s = `${obj.substring(0, maxLen)}...`;
            return noQuotes ? s : `"${s}"`;
        }
    }
    if (typeof obj === 'function') {
        const fs = `${obj}`;
        return fs.substring(0, fs.indexOf('(')).trim() + ' ()';
    }
    if (Array.isArray(obj)) {
        return `[ ${obj} ]`;
    }
    if (typeof obj === 'object') {
        let res = '';
        let propCount = 0;
        if (noQuotes && obj.hasOwnProperty) {
            for (const p in obj) {
                if (obj.hasOwnProperty(p)) {
                    propCount++;
                }
            }
        }
        if (propCount === 1) {
            for (const p in obj) {
                if (obj.hasOwnProperty(p)) {
                    return '' + obj[p];
                }
            }
        }
        if ((rec || 0) > 0 && Boolean(obj.hasOwnProperty)) {
            if (obj.hasOwnProperty('id')) {
                if (res) {
                    res += ', ';
                }
                res += 'id: ' + objProps(obj.id, 0, maxStrLen) + '';
            }
            for (const p in obj) {
                if (obj.hasOwnProperty(p) && p !== 'id') {
                    if (res) {
                        res += ', ';
                    }
                    if (typeof obj[p] !== 'function') {
                        res += p + ': ' + objProps(obj[p], (rec || 0) - 1, maxStrLen) + '';
                    }
                }
            }
        } else {
            res = 'object';
        }
        return `{ ${res} }`;
    }
    return obj;
}

export function cutString(val?: string, to?: number) {
    if (val) {
        val = val && to && val.length > to ? val.substring(0, to) + '.' : val;
    }
    return val;
}

export function toSafeString(val?: string, trim?: boolean) {
    if (!val) {
        return '';
    }
    val = val.replace(/</g, ' ').replace(/>/g, ' ');
    if (!!trim) {
        val = val.replace(/\s\s+/g, ' ');
        val = val.trim();
    }
    return val;
}

export function shuffle(a: any[]): any[] {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

// old regexp:
// export const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
// variant from stackoverflow:
export const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const nameFormat = /(^[A-Za-z]{3,16})([ ]{0,1})([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{3,16})/;
export const startsWithLetter = /([A-Za-z]+)/;

export function parseEmail(val?: string) {
    if (!val) {
        return;
    }
    if (emailFormat.test(val)) {
        return val;
    }
    return;
}

const KiB = 1024;
const MiB = KiB * KiB;
const GiB = MiB * KiB;
const TiB = GiB * KiB;

export function formatSize(value: number): string {
    if (value < KiB) {
        return `${value} bytes`;
    }
    if (value < 10 * KiB) {
        return `${round(value / KiB, 1)} KiB`;
    }
    if (value < 100 * KiB) {
        return `${round(value / KiB, 0)} KiB`;
    }
    if (value < 10 * MiB) {
        return `${round(value / MiB, 1)} MiB`;
    }
    if (value < 100 * MiB) {
        return `${round(value / MiB, 0)} MiB`;
    }
    if (value < 10 * GiB) {
        return `${round(value / GiB, 1)} GiB`;
    }
    if (value < 100 * GiB) {
        return `${round(value / GiB, 0)} GiB`;
    }
    return `${(value / TiB).toFixed(1)} TiB`;
}

// test
// [1, 10, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000, 1000000000, 10000000000, 100000000000].forEach(n => console.log(`${n} -> ${formatSize(n)}`));
// [1, 12, 123, 1234, 12345, 123456, 1234567, 12345678, 123456789].forEach(n => console.log(`${n} -> ${formatSize(n)}`));

export function nameS(n: number, name: string) {
    const isOne = n === 1 || n === -1;
    if (name === 'man' || name === 'men') {
        return isOne ? `man` : `men`;
    }
    if (name === 'woman' || name === 'women') {
        return isOne ? `woman` : `women`;
    }
    return isOne ? name : `${name}s`;
}

export function withS(n: number, name: string) {
    return `${n} ${nameS(n, name)}`;
}

export function withIs(n: number, name: string) {
    if (n === 1) {
        return 'is ' + withS(n, name);
    } else {
        return 'are ' + withS(n, name);
    }
}

export function withTh(n: number) {
    const suffix = n === 1 ? 'st' 
        : n === 2 ? 'nd'
        : n === 3 ? 'rd'
        : 'th'; 
    return `${n}${suffix}`;
}

export function isValidNumeric(d: number) {
    return !isNaN(d) && isFinite(d);
}

export function isNumeric(n: string) {
    const d = parseFloat(n);
    return isValidNumeric(d);
}

export function visible(condition: boolean): { visibility: undefined | 'hidden' } {
    return { visibility: condition ? undefined : 'hidden' };
}

export function roundDate(date: number) {
    return date - (date % DAY_MILLIS);
}

export function roundToNextDate(date: number) {
    return roundDate(date) + DAY_MILLIS;
}

export function toDateUTC(date: number) {
    return new Date(date).setHours(0, 0, 0, 0) + TIMEZONE_OFFSET_MILLIS;
}

export function getUserToday() {
    const nowDate = new Date();
    return new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0).getTime();
}

export function getUserDateNextMonth(m: number) {
    const nowDate = new Date();
    return new Date(nowDate.getFullYear(), nowDate.getMonth() + m, nowDate.getDate(), 0, 0, 0).getTime();
}

export function cleanObj(obj: any) {
    for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || (typeof obj[propName] === 'string' && (obj[propName] as string).length === 0)) {
            delete obj[propName];
        }
    }
    return obj;
}

export function isTrue(b: boolean | undefined, def: boolean): boolean {
    if (b === undefined) {
        return def;
    }
    return b;
}

export function equalArrays<T>(arrayA: readonly T[] | undefined, arrayB: readonly T[] | undefined, areElementsEqual: (v1: T, v2: T) => boolean = (v1: T, v2: T) => v1 === v2): boolean {
    if (arrayA === arrayB) {
        return true;
    }
    if (arrayA == null || arrayB == null || arrayA.length !== arrayB.length) {
        return false;
    }
    for (let it = 0; it < arrayA.length; ++it) {
        if (!areElementsEqual(arrayA[it], arrayB[it])) {
            return false;
        }
    }
    return true;
}

export function makeParameterizedGetRequestUrl<T>(baseUrl: string, paramName: string, paramValues: Array<T>) {
    return `${baseUrl}?${paramValues.map(val => `${paramName}=${val}`).join('&')}`;
}

export function parseInt(val: string, min?: number, max?: number) {
    const res = Number.parseInt(val, 10);
    min = min ?? Number.MIN_VALUE;
    max = max ?? Number.MAX_VALUE;
    if (isValidNumeric(res)) {
        if (res <= min) {
            return min;
        }
        if (res >= max) {
            return max;
        }
        return res;
    } else {
        return NaN;
    }
}

export function absCoords(x: number, y: number, r: number, angle: number) {
    const angleRad = angle * Math.PI / 180.0;
    return {
        x: x + (r * Math.cos(angleRad)),
        y: y + (r * Math.sin(angleRad))
    };
}

export function svgArc(x: number, y: number, r: number, angle1: number, angle2: number) {
    const start = absCoords(x, y, r, angle2);
    const end = absCoords(x, y, r, angle1);
    const largeArcFlag = angle2 - angle1 <= 180 ? 0 : 1;
    return [
        'M', start.x, start.y,
        'A', r, r, 0, largeArcFlag, 0, end.x, end.y
    ].join(' ');
}

export const isCurrNumber = /^((\d+\.?|\.(?=\d))?\d{0,2})$/;

export function getServerErrorMessage(err: any): string {
    let res: string = err;
    if (err.response && err.response.data) {
        res = err.response.data;
    } else if (err.message) {
        res = err.message;
    }
    return res;
}

export function findGetParameter(parameterName: string): string | undefined {
    let result: string | undefined;
    location.search
        .substring(1)
        .split('&')
        .forEach(item => {
            const tmp = item.split('=');
            if (tmp[0] === parameterName) {
                result = decodeURIComponent(tmp[1]);
            }
        });
    return result;
}

export function logInfo(...data: any[]) {
    console.log(`[${Date.now()}]`, ...data);
}

export function logError(...data: any[]) {
    console.error(`[${Date.now()}]`, ...data);
}

export function dbgLog(...data: any[]) {
    if (DEBUG) {
        logInfo(...data);
    }
}
