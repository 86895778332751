
export const defaultTeeExplanation = 'Select the default tees here. You can always override tees for individual golfers from the Golfers / List.';
export const flightExplanation = 'Flights are a series of smaller competitions within a golf tournament. A flight is a grouping of golfers who are competing ' +
    'against one another, instead of the entire field. Each flight is typically made up of golfers of similar scoring ability.';
export const carryoversUncheckedExplanation = 'Unchecked without carryovers means any tied holes are not awarded. Winners are holes won by one golfer, if payouts are used the skins purse will be divided amongst the number of winners. ';
export const carryoversCheckedExplanation = 'Checked carryovers means any tied holes will be carried over, and accumulate to the next hole won. If payouts are used the skins purse will be divided amongst the number of holes played, with each hole having the same value.';

export const FREE_OPTS = [
    'Up to 100 participants in the event',
    'Single-day tournaments',
    'Multiple scoring formats and side games',
    'Real-time scoring and standings with free Golf Pad GPS app',
];

export const PRO_OPTS = [
    'Organize Multi-day tournaments',
    'USGA - Automatic handicap lookup',
    'PayPal integration for event fees/payouts',
    'Live video support',
    'Unlimited field size',
];

export const PRO_OPTS_EXT = [
    'Sponsorship - events site banner, email, printed, etc.',
    'Duplicate - copy a previous event',
    'Leagues and more, coming soon',
];
