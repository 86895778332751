import axios from 'axios';
import * as React from 'react';
import { ButtonGroup, CircularProgress, Grid, Typography } from '@mui/material';
import { useAppStyles } from 'src/styles';
import { AppColors } from 'src/main/Theme';
import { useUserAware } from './Auth';
import AppButton from 'src/common/components/AppButton';
import { Flex, FlexCenter, Spacing } from 'src/common/Misc';
import { DoneIcon } from 'src/common/Icons';
import ProBadge from 'src/common/ProBadge';
import { FREE_OPTS, PRO_OPTS, PRO_OPTS_EXT } from 'src/util/strings';
import { Product, SubscriptionInterval } from 'src/types/EventTypes';
import { AccountActions } from './StripeRedirect';
import { formatCurrency, getServerErrorMessage } from 'src/util/utility';
import { showProgress } from 'src/redux/ReduxConfig';
import { prepareRequest } from 'src/event/Event';
import { Urls } from 'src/util/config';

export default function AccountPlans({ variant, action }: { variant: 'short' | 'long', action: (name?: AccountActions) => void }) {
    const [subscription, setSubscription] = React.useState<SubscriptionInterval>('year');
    const [products, setProducts] = React.useState<Array<Product> | undefined>(undefined);
    const classes = useAppStyles();
    const userAware = useUserAware();
    const pro = userAware.hasPro;
    const freeOpts = FREE_OPTS;
    const proOpts = variant === 'long' ? PRO_OPTS.concat(PRO_OPTS_EXT) : PRO_OPTS;
    const prodYearly = products?.find(p => p.interval === 'year');
    const prodMonthly = products?.find(p => p.interval === 'month');
    const accountAction = subscription === 'year' ? 'upgrade_yearly' : subscription === 'month' ? 'upgrade_mnthly' : undefined;
    const priceLabel = subscription === 'year' && prodYearly ?
        <>
            {formatCurrency(prodYearly.currency, prodYearly.price / 100)}<span style={{ color: AppColors.webGrey400, fontWeight: 400 }}>/year</span>
            &nbsp;
            (only {formatCurrency(prodYearly.currency, prodYearly.price / 12 / 100)} a month)
        </> : subscription === 'month' && prodMonthly ?
            <>
                {formatCurrency(prodMonthly.currency, prodMonthly.price / 100)}<span style={{ color: AppColors.webGrey400, fontWeight: 400 }}>/month</span>
            </> :
            undefined;
    const diffPrice = prodMonthly && prodYearly ? ((prodMonthly.price - prodYearly.price / 12) / prodMonthly.price * 100).toFixed(0) + '%' : undefined;
    React.useEffect(() => {
        async function loadProductsInfo() {
            if (products) {
                return;
            }
            setProducts([]);
            const hideProgress = showProgress('signInWithToken');
            try {
                const request = await prepareRequest({ token: '', action: '', source: '' });
                setProducts((await axios.post(Urls.listProducts, request)).data.products);
                hideProgress();
            } catch (err) {
                hideProgress('Failed to load product prices info: ' + getServerErrorMessage(err));
            }
        }
        loadProductsInfo();
    });
    const payModeButtons = <>
        <ButtonGroup size="small">
            <AppButton
                sx={{
                    width: 60,
                    borderRadius: '6px',
                    color: subscription === 'year' ? AppColors.white : AppColors.webGreen500,
                    border: `1px solid ${AppColors.webGreen500}`,
                    backgroundColor: subscription === 'year' ? AppColors.webGreen500 : AppColors.white,
                    '&:hover': {
                        backgroundColor: subscription === 'year' ? AppColors.webGreen600 : AppColors.webGreen100,
                    }
                }}
                onClick={() => setSubscription('year')}>
                Yearly
            </AppButton>
            <AppButton
                sx={{
                    width: 68,
                    borderRadius: '6px',
                    color: subscription === 'month' ? AppColors.white : AppColors.webGreen500,
                    border: `1px solid ${AppColors.webGreen500}`,
                    backgroundColor: subscription === 'month' ? AppColors.webGreen500 : AppColors.white,
                    '&:hover': {
                        backgroundColor: subscription === 'month' ? AppColors.webGreen600 : AppColors.webGreen100,
                    }
                }}
                onClick={() => setSubscription('month')}>
                Monthly
            </AppButton>
        </ButtonGroup>
    </>;
    const savinggTextForMonth = <>
        <span style={{ color: AppColors.webGreen300, fontWeight: 600 }}>Save {diffPrice}</span> on a yearly subscription
    </>;
    const savinggTextForYear = <>
        You're saving <span style={{ color: AppColors.webGreen300, fontWeight: 600 }}>{diffPrice}</span> on the annual plan
    </>;
    return (
        <div style={{ width: '100%' }}>
            <div style={{ textAlign: 'center' }} className={classes.listRootGrey}>
                <FlexCenter>
                    <Typography sx={{ fontSize: 24, fontWeight: 500, maxWidth: variant === 'short' ? 430 : undefined }}>
                        Upgrade to Pro — Get Unlimited Experience and Priority Support
                    </Typography>
                </FlexCenter>
                {variant === 'long' && <>
                    <Typography sx={{ fontSize: 16, fontWeight: 400, color: AppColors.webGrey400 }}>
                        Use Events Pro to organize great events on a professional level
                    </Typography>
                    <Spacing />
                    {diffPrice ?
                        <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                            {subscription === 'year' ? savinggTextForYear : savinggTextForMonth}
                        </Typography> :
                        <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                            &nbsp;
                        </Typography>}
                    <Spacing height={4} />
                    {payModeButtons}
                </>}
                <Spacing />
                <FlexCenter>
                    <Grid container className={classes.settingsPlanRoot}>
                        <Grid item sm={12} md={6} className={classes.settingsPlanTab}>
                            <div className={classes.settingsPlanTabContent}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 330 }}>
                                    <div>
                                        <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Free plan</Typography>
                                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>Free</Typography>
                                        {freeOpts.map((opt, idx) => <div key={idx}>
                                            <Flex>
                                                <DoneIcon sx={{ color: AppColors.white, backgroundColor: AppColors.webGrey200 }} className={classes.roundIcon} />
                                                <Typography key={idx}>{opt}</Typography>
                                            </Flex>
                                            <Spacing height={4} />
                                        </div>)}
                                    </div>
                                    <div>
                                        {!pro &&
                                            <div>
                                                <Typography style={{ backgroundColor: AppColors.webGrey150, textAlign: 'center', padding: 6, borderRadius: 20 }}>
                                                    You are on free plan
                                                </Typography>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item sm={12} md={6} className={classes.settingsPlanTab}>
                            <div className={classes.settingsPlanTabContent} style={{ backgroundColor: AppColors.webGreen100 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 330 }}>
                                    <div>
                                        <Typography sx={{ fontSize: 20, fontWeight: 500 }} component={'span'}>
                                            <Flex>
                                                Events Pro &nbsp;
                                                <ProBadge />
                                                <span style={{ flex: '1 1 0%' }} />
                                                {variant === 'short' && payModeButtons}
                                            </Flex>
                                        </Typography>
                                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                                            {priceLabel ?
                                                priceLabel :
                                                <span style={{ color: AppColors.webGrey400, fontWeight: 400 }}><CircularProgress size={16} /> loading prices...</span>}
                                        </Typography>
                                        {proOpts.map((opt, idx) => <div key={idx}>
                                            <Flex>
                                                <DoneIcon sx={{ color: AppColors.white, backgroundColor: AppColors.webGreen300 }} className={classes.roundIcon} />
                                                <Typography key={idx}>{opt}</Typography>
                                            </Flex>
                                            <Spacing height={4} />
                                        </div>)}
                                        <Flex>
                                            <DoneIcon sx={{ color: AppColors.webGreen500, backgroundColor: AppColors.white }} className={classes.roundIcon} />
                                            <Typography>New features coming soon</Typography>
                                        </Flex>
                                        <Spacing height={8} />
                                    </div>
                                    <div>
                                        {pro ? <>
                                            <Typography style={{ backgroundColor: AppColors.webGreen300, color: AppColors.white, fontWeight: 600, textAlign: 'center', padding: 6, borderRadius: 20 }}>
                                                You are on Pro plan
                                            </Typography>
                                            <Spacing height={8} />
                                            <AppButton
                                                style={{ width: '100%', backgroundColor: AppColors.white, color: AppColors.webGreen300, border: `1px solid ${AppColors.webGreen300}`, fontWeight: 600 }}
                                                onClick={() => action('manage_subscription')}>
                                                Manage subscription
                                            </AppButton>
                                        </> : variant === 'short' ? <>
                                            <AppButton
                                                style={{ width: '100%', backgroundColor: AppColors.webGreen500 }}
                                                onClick={() => action('learn_more')}>
                                                Learn more
                                            </AppButton>
                                            <Spacing height={4} />
                                            <AppButton
                                                style={{ width: '100%', backgroundColor: AppColors.white, color: AppColors.webGreen500, border: `1px solid ${AppColors.webGreen500}` }}
                                                onClick={() => action(accountAction)}>
                                                Upgrade
                                            </AppButton>
                                        </> : <>
                                            <AppButton
                                                style={{ width: '100%', backgroundColor: AppColors.webGreen500 }}
                                                onClick={() => action(accountAction)}>
                                                Upgrade
                                            </AppButton>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </FlexCenter>
                <Spacing />
                {variant === 'long' && <>
                    <FlexCenter>
                        <Typography sx={{ fontSize: 14, fontWeight: 400, maxWidth: 430 }}>
                            Still have questions? Contact us and we will answer about how you can use Events Pro to your advantage
                        </Typography>
                    </FlexCenter>
                    <Spacing />
                    <AppButton color="inherit">
                        Contact us
                    </AppButton>
                    <Spacing />
                    <Spacing />
                </>}
            </div>
        </div >
    );
}
